import { target, targetable } from '@github/catalyst/lib/targetable'
import { cropCanvasAndExportToPNG } from '../submission_form/crop_canvas'

export default targetable(class extends HTMLElement {
  static [target.static] = ['canvas', 'input', 'clear', 'button']

  async connectedCallback() {
    const scale = 3;

    // === Inisialisasi untuk Signature Draw ===
    if (this.canvas) {
      this.canvas.width = this.canvas.parentNode.clientWidth * scale;
      this.canvas.height = this.canvas.parentNode.clientHeight * scale;
      this.canvas.getContext('2d').scale(scale, scale);

      const { default: SignaturePad } = await import('signature_pad');
      this.pad = new SignaturePad(this.canvas);

      if (this.clear) {
        this.clear.addEventListener('click', (e) => {
          e.preventDefault();
          this.pad.clear();
        });
      }

      if (this.button) {
        this.button.addEventListener('click', (e) => {
          e.preventDefault();
          this.button.disabled = true;
          this.submit(this.canvas);
        });
      }
    }

    // === Inisialisasi untuk Signature Text (Ttd) ===
    const buttonTtd = document.getElementById('ttdButton');
    const canvas2 = document.getElementById("canvas2");
    const signatureTextInput = document.getElementById("signature-text");
    const fontSelect = document.getElementById("font-select");
    const buttonClear = document.getElementById('clearText')

    if (canvas2 && signatureTextInput && fontSelect && buttonTtd) {
      const ctx2 = canvas2.getContext("2d");
      if (!ctx2) return;

      this.canvasContext2 = ctx2;

      signatureTextInput.addEventListener("input", () => this.updateCanvasText(canvas2, ctx2));
      fontSelect.addEventListener("change", () => this.updateCanvasText(canvas2, ctx2));

      this.updateCanvasText(canvas2, ctx2);

      // Event listener untuk tombol Save di mode Ttd
      buttonTtd.addEventListener('click', (e) => {
        e.preventDefault();
        buttonTtd.disabled = true;
        this.submit(canvas2); // Submit menggunakan canvas untuk Ttd
      });

      if (buttonClear) {
        buttonClear.addEventListener('click', (e) => {
          e.preventDefault();
          signatureTextInput.value = "";
          ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
        });
      }
    }
  }

  updateCanvasText(canvas, ctx) {
    const signatureTextInput = document.getElementById("signature-text");
    const fontSelect = document.getElementById("font-select");

    if (!canvas || !ctx || !signatureTextInput || !fontSelect) return;

    const text = signatureTextInput.value.trim();
    const selectedFont = fontSelect.value;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.font = `30px ${selectedFont}`;
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
  }

  async submit(selectedCanvas) {
    const blob = await cropCanvasAndExportToPNG(selectedCanvas);
    const file = new File([blob], 'signature.png', { type: 'image/png' });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    if (this.input) {
      this.input.files = dataTransfer.files;

      if (this.input.webkitEntries.length) {
        this.input.dataset.file = `${dataTransfer.files[0].name}`;
      }
    }

    this.closest('form').requestSubmit();
  }
});
