<template>
  <div :class="class">
    <label :for="`date-picker-${inputName}`" class="text-sm font-medium text-gray-700 mb-1">
      {{ title }}
    </label>

    <div class="flex items-center">
      <VueDatePicker
        v-model="dateRange"
        :id="`date-picker-${inputName}`"
        :name="inputName"
        :range="false"
        :class="[
          'h-full focus:outline-none focus:ring-2 focus:ring-blue-500 max-w-xl',
          { 'ring-2 ring-blue-500': isFocused }
        ]"
        @focus="isFocused = true"
        @blur="isFocused = false"
        format="yyyy/MM/dd HH:mm"
        placeholder="yyyy/mm/dd hh:mm"
        :enable-time-picker="true"
      />
    </div>
  </div>
</template>

<script>
import { IconSearch } from '@tabler/icons-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'DateTimePicker',
  props: {
    title: {
      type: String,
      required: true
    },
    inputName: {
      type: String,
      required: true
    },
    class: {
      type: String,
      default: ''
    }
  },
  components: { VueDatePicker, IconSearch },
  data() {
    return {
      dateRange: [],
      isFocused: false
    };
  },
  created() {
    this.prefillDateRangeFromUrl();
  },
  methods: {
    prefillDateRangeFromUrl() {
      const params = new URLSearchParams(window.location.search);
      const startDateParam = params.get('created_at_gteq');
      const endDateParam = params.get('created_at_lteq');

      if (startDateParam && endDateParam) {
        const startDate = new Date(startDateParam);
        const endDate = new Date(endDateParam);

        if (!isNaN(startDate) && !isNaN(endDate)) {
          this.dateRange = [startDate, endDate];
        }
      }
    },
    submitDateRange() {
      const params = new URLSearchParams(window.location.search);

      if (this.dateRange && this.dateRange.length === 2) {
        const startDate = new Date(this.dateRange[0]).toISOString().split('T')[0];
        const endDate = new Date(this.dateRange[1]).toISOString().split('T')[0];

        params.set('created_at_gteq', startDate);
        params.set('created_at_lteq', endDate);
      } else {
        params.delete('created_at_gteq');
        params.delete('created_at_lteq');
      }

      window.location.search = params.toString();
    }
  }
};
</script>
