<template>
  <div class="relative">
    <!-- Dropdown Trigger -->
    <button
      type="button"
      @click="toggleDropdown"
      class="base-input"
    >
      {{ selected?.name || "Select Department" }}
    </button>
    <small v-if="checkMessage()" class="text-xs text-red-500">Please fill in your department</small>
    <input type="hidden" name="user[department_id]" id="user_department_id" v-model="selected.id" />
    <input type="hidden" name="user[department_name]" id="user_department_name" v-model="selected.name" />
    <!-- Dropdown Menu -->
    <div
      v-if="isOpen"
      class="absolute z-10 mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg"
    >
      <ul>
        <li
          v-for="department in dataDepartments"
          :key="department"
          class="w-full px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between"
        >
          <button
            type="button"
            v-if="!isEditing || editedDepartment !== department"
            @click="selectDepartment(department)"
            class="w-full text-left"
          >
            {{ department.name }}
          </button>
          <input
            v-if="isEditing && editedDepartment === department"
            v-model="editedDepartment.name"
            type="text"
            class="base-input w-full"
          />
          <button
            v-if="!isEditing && editedDepartment !== department"
            type="button"
            @click.stop="editDepartment(department)"
            class="ml-2 text-blue-500 hover:text-blue-700"
          >
            Edit
          </button>
          <button
            v-if="isEditing && editedDepartment === department"
            type="button"
            @click.stop="saveEditedDepartment(department)"
            class="ml-2 text-blue-500 hover:text-blue-700"
          >
            Save
          </button>
        </li>
        <li class="w-full px-4 py-2">
          <input
            v-model="newDepartment"
            type="text"
            placeholder="Add new department"
            class="base-input w-full"
          />
          <button
            type="button"
            @click="addDepartment"
            class="base-button w-full mt-2"
          >
            Add Department
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownDepartment",
  props: {
    value: String,
    departments: String,
    accountId: String,
    errorMessage: {
      type: Array,
      default: [],
    },
    adminRole: String
  },
  data() {
    return {
      isOpen: false,
      isEditing: false,
      selected: JSON.parse(this.value),
      newDepartment: null,
      editedDepartment: null,
      dataDepartments: JSON.parse(this.departments),
    };
  },
  methods: {
    toggleDropdown() {
      if (this.adminRole !== "true") {        
        this.isOpen = !this.isOpen;
      }
    },
    selectDepartment(department) {
      this.selected = department; // Update the displayed value
      this.isOpen = false; // Close dropdown
      this.$emit("input", department); // Emit the value to the parent
    },
    addDepartment() {
      if (this.newDepartment.trim() !== "") {
        this.dataDepartments.push({account_id: this.accountId, name: this.newDepartment.trim()});
        this.newDepartment = ""; // Clear input
      }
    },
    editDepartment(department) {
      this.isEditing = true;
      this.editedDepartment = department;
    },
    saveEditedDepartment(index) {
      this.dataDepartments[index] = this.editedDepartment;
      this.isEditing = false;
      this.editedDepartment = null;
    },
    checkMessage() {
      return this.errorMessage && this.errorMessage.join().includes("epartment");
    },
  },
};
</script>
